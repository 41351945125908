import React, { useState, useEffect } from "react"

import { graphql } from "gatsby"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Helmet from "react-helmet"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
// import SafeAreaWrapper from "../../components/SafeAreaWrapper"
import { Simple as Card } from "../../components/Card"

import useSiteMetadata from "../../hooks/useSiteMetadata"

import Grid from "../../components/Grid"
import ScheduleDemoForm from "../../components/ScheduleDemoForm"

// import { Button } from "../../components/Button"
// import Hero from "../../components/Hero"
// import Container from "../../components/Container"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

import isAbsoluteUrl from "is-absolute-url"

import { useMemo } from "react"
import { toUrl } from "gatsby-source-gravatar"

function Profile({ email }) {
	const url = useMemo(() => toUrl(email, "size=128"), [email])

	return <img src={url} alt={`${email}'s gravatar`} />
}

const SocialShareButtons = ({ title, url }) => {
	const socialShareText = (title, url) => {
		const variants = [
			`Check out this great article I just found from @hanseaticsoft: ${title} - ${url}`,
			`${title}: Another great read by @hanseaticsoft. ${url}`,
			`${title} - Thank you @hanseaticsoft for this great article! ${url}`,
		]

		return variants[Math.floor(Math.random() * variants.length)]
	}
	return (
		<>
			<a
				href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
				target="_blank"
				rel="noreferrer"
				title={`Share ${title} on LinkedIn`}
				data-umami-event={`Share ${title} on LinkedIn`}>
				<span className="w-40 h-40 bg-[#0a66c2] rounded-full flex justify-center items-center ">
					<svg
						className="fill-current text-white"
						width="21"
						height="21"
						xmlns="http://www.w3.org/2000/svg">
						<path d="M20.585 20.296h-4.14v-6.485c0-1.546-.029-3.537-2.155-3.537-2.156 0-2.486 1.685-2.486 3.425v6.597H7.663V6.96h3.975v1.822h.056a4.362 4.362 0 0 1 3.922-2.154c4.197 0 4.97 2.76 4.97 6.352l-.001 7.316ZM2.99 5.137A2.414 2.414 0 0 1 .587 2.734 2.415 2.415 0 0 1 2.99.331a2.415 2.415 0 0 1 2.403 2.403A2.414 2.414 0 0 1 2.99 5.137m2.07 15.16H.917V6.96H5.06v13.336Z" />
					</svg>
				</span>
			</a>
			<a
				href={`https://twitter.com/intent/tweet?text=${socialShareText(title, url)}`}
				target="_blank"
				rel="noreferrer"
				title={`Share ${title} on Twitter`}
				data-umami-event={`Share ${title} on Twitter`}>
				<span className="w-40 h-40 bg-[#1d9bf0] rounded-full flex justify-center items-center ">
					<svg
						className="fill-current text-white"
						width="20"
						height="19"
						xmlns="http://www.w3.org/2000/svg">
						<path d="M17.644 3.308c.848-.568 1.498-1.47 1.803-2.543a7.698 7.698 0 0 1-2.606 1.116C16.094.986 15.027.429 13.846.429c-2.265 0-4.101 2.058-4.101 4.597 0 .36.034.712.104 1.047-3.409-.192-6.432-2.02-8.457-4.804a5.017 5.017 0 0 0-.555 2.313c0 1.594.724 3.002 1.825 3.828a3.786 3.786 0 0 1-1.859-.575v.057c0 2.228 1.413 4.087 3.293 4.508a3.61 3.61 0 0 1-1.083.162 3.64 3.64 0 0 1-.77-.082c.52 1.826 2.036 3.157 3.832 3.193C4.67 15.907 2.901 16.64.979 16.64A7.3 7.3 0 0 1 0 16.578c1.816 1.303 3.973 2.065 6.29 2.065 7.548 0 11.673-7.006 11.673-13.083 0-.2-.002-.398-.01-.594A8.936 8.936 0 0 0 20 2.585a7.517 7.517 0 0 1-2.356.723Z" />
					</svg>
				</span>
			</a>
			<a
				href={`whatsapp://send?text=${socialShareText(title, url)}`}
				title={`Share ${title} on WhatsApp`}
				data-umami-event={`Share ${title} on WhatsApp`}>
				<span className="w-40 h-40 bg-[#5ab962] rounded-full flex justify-center items-center">
					<svg
						className="fill-current text-white"
						width="20"
						height="20"
						xmlns="http://www.w3.org/2000/svg">
						<path d="M10.178 0C15.594 0 20 4.374 20 9.75c0 5.375-4.406 9.748-9.822 9.748-1.5 0-2.983-.341-4.32-.99l-.33-.17-5.19 1.65a.258.258 0 0 1-.264-.066.26.26 0 0 1-.073-.209l.013-.056L1.7 14.68A9.678 9.678 0 0 1 .354 9.75C.354 4.374 4.761 0 10.178 0Zm0 1.2c-4.756 0-8.624 3.838-8.624 8.55 0 1.406.347 2.784 1.008 4.014l.172.305.28.474-1.267 3.738 3.9-1.24.446.24a8.69 8.69 0 0 0 4.085 1.017c4.755 0 8.622-3.838 8.622-8.548 0-4.712-3.867-8.55-8.622-8.55ZM7.256 5c.287 0 .494.17.651.536.065.152.678 1.596.712 1.663.042.08.172.328.02.621l-.032.064c-.062.123-.116.229-.236.365l-.117.136c-.083.098-.169.198-.248.275a.46.46 0 0 0-.085.098s.001.017.024.056c.162.267 1.344 1.855 2.901 2.515.068.029.293.119.306.119.014 0 .038-.024.056-.044.136-.15.575-.65.72-.86.128-.187.292-.283.488-.283.12 0 .233.036.338.073.258.09 1.71.788 1.749.807.19.089.34.159.427.299.127.205.077.772-.115 1.29-.242.659-1.31 1.198-1.795 1.24l-.071.007a3.92 3.92 0 0 1-.399.023h-.07c-.389-.01-1.056-.096-2.38-.611-1.46-.57-2.9-1.79-4.055-3.437l-.04-.058C5.702 9.506 5 8.486 5 7.405c0-1.2.589-1.883.937-2.097.328-.202 1.07-.298 1.207-.303A7.3 7.3 0 0 0 7.224 5Z" />
					</svg>
				</span>
			</a>
			<a
				href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`}
				target="_blank"
				rel="noreferrer"
				title={`Share ${title} on Facebook`}
				data-umami-event={`Share ${title} on Facebook`}>
				<span className="w-40 h-40 bg-[#1877f2] rounded-full flex justify-center items-center">
					<svg
						className="fill-current text-white"
						width="10"
						height="23"
						xmlns="http://www.w3.org/2000/svg">
						<path d="M6.3 22.143H2.1V11.427H0V7.735h2.1V5.518C2.1 2.506 3.283.714 6.65.714h2.803v3.694H7.703c-1.311 0-1.398.516-1.398 1.479l-.006 1.848h3.175l-.372 3.692H6.3v10.716Z" />
					</svg>
				</span>
			</a>
			<a
				href={`mailto:?subject=I just read ${title}&body=I just read this great article by Hanseaticsoft: ${title}. Take a look, I think you will find this one insightful too! ${url}`}
				title={`Share ${title} via email`}
				data-umami-event={`Share ${title} via E-Mail`}>
				<span className="w-40 h-40 bg-tealblue rounded-full flex justify-center items-center">
					<svg
						className="fill-current text-white"
						width="20"
						height="14"
						xmlns="http://www.w3.org/2000/svg">
						<path d="m1 0 9 8 9-8H1Zm19 1-10 9L0 1v12.001c0 .552.455.999.992.999h18.016a.995.995 0 0 0 .992-.999V1Z" />
					</svg>
				</span>
			</a>
		</>
	)
}

const BlogPage = ({
	location,
	data: {
		backgroundImage,
		mongodbBloghanseaticsoftArticles: {
			slug,
			published_at,
			dateModified,
			datePublished,
			author,
			excerpt,
			title,
			markdown: {
				childMdx: { body },
			},
			seoImage,
			seoImage1x1,
			seoImage4x3,
			seoImage16x9,
			teaser_image,
			firstName,
			lastName,
			email,
			form,
		},
	},
}) => {
	const { siteUrl } = useSiteMetadata()
	const components = {
		h1: props => <h1 className="display1 mt-40 mb-30" {...props} />,
		h2: props => <h2 className="display2 mt-40 mb-30" {...props} />,
		h3: props => <h3 className="display3 mt-40 mb-30" {...props} />,
		h4: props => <h4 className="display4 mt-40 mb-12" {...props} />,
		h5: props => <h4 className="display4 mt-40 mb-12" {...props} />,
		h6: props => <h4 className="display4 mt-40 mb-12" {...props} />,
		img: ({ src, alt = null, title, props }) => {
			title = title ? title.replace(/\{.*\}/g, "") : null
			return (
				<figure className="md:my-50 lg:my-60">
					<img
						className="rounded-small"
						title={title}
						alt={alt || title}
						src={isAbsoluteUrl(src) ? src : `https://hanseaticsoft.imgix.net/${src}`}
						{...props}
					/>
					{title && (
						<figcaption className="psmall text-tealblue text-md mt-10">
							{title}
						</figcaption>
					)}
				</figure>
			)
		},
		a: props => (
			<a
				className="text-tealblue underline underline-offset-2 decoration-2"
				target={isAbsoluteUrl(props.href) ? "_blank" : ""}
				{...props}
			/>
		),
		p: props => <p className="bodyp" {...props} />,
		blockquote: props => (
			<blockquote
				className="my-10 mr-20 p-20 border-porcelain border-l-4 text-sm italic font-semibold"
				{...props}
			/>
		),
		cite: props => <cite className="text-sirocco" {...props} />,
		small: props => <small className="text-sirocco" {...props} />,
		ul: props => <ul className="list-disc mt-16 mb-30 ml-30 mr-50" {...props} />,
		li: props => <li className="bodyp text-nevada leading-28 text-base my-8" {...props} />,

		WideImg: ({ src, alt = null, title, props }) => {
			title = title ? title.replace(/\{.*\}/g, "") : null
			return (
				<figure className="md:my-50 lg:my-60">
					<img
						className="rounded-small"
						title={title}
						alt={alt || title}
						src={isAbsoluteUrl(src) ? src : `https://hanseaticsoft.imgix.net/${src}`}
						{...props}
					/>
					{title && (
						<figcaption className="psmall text-tealblue text-md mt-10">
							{title}
						</figcaption>
					)}
				</figure>
			)
		},

		QuoteBox: props => (
			<div className="rounded-medium my-spacer-md bg-pictonblue overflow-hidden">
				<figure class="pr-30 py-30 pl-20 bg-porcelain ml-[9px]">
					<blockquote className="mb-spacer-md">
						<p className="font-headline bodyp leading-8 text-jetblack">
							{props.children}
						</p>
					</blockquote>
					<figcaption className="flex items-center mt-16 space-x-16">
						<img
							className="w-[60px] h-[60px] rounded-full border-white border-2"
							src={props.photoUrl}
						/>
						<div>
							<div className="text-md font-medium text-jetblack">{props.name}</div>
							<div className="text-md font-normal text-nevada">{props.title}</div>
						</div>
					</figcaption>
				</figure>
			</div>
		),
	}

	const meta = [
		{ name: `og:url`, content: `${siteUrl}${location.pathname}` },
		{ name: `og:image`, content: seoImage.imgixImage.url },
		{ name: `twitter:url`, content: `${siteUrl}${location.pathname}` },
		{ name: `twitter:image`, content: seoImage.imgixImage.url },
	]

	const [isFormSubmitted, setIsFormSubmitted] = useState(false)

	return (
		<Layout translucent={false}>
			<Seo title={title} description={excerpt} meta={meta} />

			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org",
						"@type": "BlogPosting",
						headline: title,
						image: [
							seoImage1x1.imgixImage.url,
							seoImage4x3.imgixImage.url,
							seoImage16x9.imgixImage.url,
						],
						datePublished: datePublished,
						dateModified: dateModified,
						author: [
							{
								"@type": "Person",
								name: `${author.firstName} ${author.lastName}`,
								url: "https://www.linkedin.com/in/max-hagemann",
							},
						],
					})}
				</script>
			</Helmet>

			<div className={`w-full relative`}>
				<Grid className="mt-60">
					{/* h-[448px] lg:h-[508px] xl:h-[540px] */}
					<p className="mt-spacer-xl col-start-1 col-end-13 text-left md:text-center leading-18 text-sm text-nevada">
						{published_at}
					</p>
					<h1 className="col-start-1 col-end-12 md:col-start-2 md:col-end-12 lg:col-start-3 lg:col-end-11 mt-20 display1 text-left md:text-center text-jetblack">
						{title}
					</h1>
					<h2 className="col-start-1 col-end-12 md:col-start-2 md:col-end-12 lg:col-start-4 lg:col-end-10 font-sans font-normal text-base leading-28 text-left md:text-center text-jetblack my-20">
						{excerpt}
					</h2>
					<p className="col-start-1 col-end-13 text-silversand text-xs leading-16 captionSmall text-left md:text-center mb-[50px] md:mb-[170px]">
						By {author.firstName} {author.lastName}
					</p>
				</Grid>
				{/* <BgImage
					alt={title}
					Tag="div"
					className="bg-cover bg-center"
					image={getImage(backgroundImage)}>
				</BgImage> */}
				<div className="md:container md:-mt-[120px]">
					<div className="md:grid md:grid-cols-12 gap-x-20 md:gap-x-40">
						<div className="w-full md:col-start-1 md:col-end-13 lg:col-start-2 lg:col-end-12">
							<div className="relative">
								<div className="w-full md:rounded-medium overflow-hidden ">
									<GatsbyImage
										imgClassName="md:rounded-medium overflow-hidden"
										className="overflow-hidden md:rounded-medium h-[250px] md:h-[400px] xl:h-550"
										image={teaser_image.imgixImage.gatsbyImageData}
										alt={title}
										title={title}
										objectFit="cover"
										objectPosition="center center"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<article className="mt-spacer-md">
				<Grid className="">
					{/* <div className="hidden xl:flex flex-col justify-start items-center col-start-1 col-end-2 -mt-spacer-xl mb-spacer-lg py-40 gap-y-10">
						<SocialShareButtons title={title} url={`${siteUrl}${location.pathname}`} />
					</div> */}

					<div
						className={`col-start-1 col-end-13 md:col-start-2 md:col-end-12 ${
							form.show
								? "xl:col-start-2 xl:col-end-8"
								: "xl:col-start-4 xl:col-end-10"
						}`}>
						<MDXProvider components={components}>
							<MDXRenderer>{body}</MDXRenderer>
						</MDXProvider>
					</div>

					{form.show && (
						<aside
							className={`mt-spacer-md col-start-1 col-end-13 md:col-start-2 md:col-end-12 xl:mt-0 xl:col-start-8 xl:col-end-12`}>
							<div className="bg-porcelain rounded-medium px-30 py-40">
								{isFormSubmitted ? (
									<>
										<div className="mb-30 col-span-12 grow-0 flex justify-center">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 73 75"
												className="w-[73px] h-[75px]">
												<g fill="none">
													<path
														fill="#FFF"
														d="M24.181 30.565 50.086 56.47 8.548 72.103 24.18 30.565ZM47.99 33.23l4.605.323-.43 6.138-4.604-.323.43-6.138ZM9.922 5.317l5.483 2.792-2.096 4.113-5.483-2.793 2.096-4.112Zm59.386 31.145a1.539 1.539 0 1 1-.001 3.078 1.539 1.539 0 0 1 0-3.078Zm-20-24.616a1.539 1.539 0 1 1-.001 3.078 1.539 1.539 0 0 1 0-3.078Z"
													/>
													<path
														fill="#50ADF1"
														d="M24.181 30.565 50.086 56.47 8.548 72.103 24.18 30.565ZM47.99 33.23l4.605.323-.43 6.138-4.604-.323.43-6.138ZM9.922 5.317l5.483 2.792-2.096 4.113-5.483-2.793 2.096-4.112Zm59.386 31.145a1.539 1.539 0 1 1-.001 3.078 1.539 1.539 0 0 1 0-3.078Zm-20-24.616a1.539 1.539 0 1 1-.001 3.078 1.539 1.539 0 0 1 0-3.078Z"
														opacity=".3"
													/>
													<path
														fill="#50ADF1"
														d="m21.878 18.968 10.78 10.781-.273-1.734 6.078-.963.722 4.559-4.028.637 14.05 14.05 7.595 7.593L.915 74.923H.82l21.058-55.955ZM9.351 60.985l-3.276 8.707 12.022-4.523c-2.937-1.094-5.852-2.489-8.746-4.184Zm4.238-11.256-3.137 8.331.276.165c3.698 2.183 7.418 3.843 11.163 4.98l.8.233 13.634-5.129c-7.753-.832-15.336-3.697-22.736-8.58Zm52.151 4.637 5.102 3.442-2.582 3.826-5.102-3.442 2.582-3.826ZM18.46 36.79l-3.757 9.983c9.319 6.299 18.848 9.173 28.63 8.656l.762-.045 7.154-2.692-3.566-3.565c-9.975-.774-19.723-4.898-29.223-12.337Zm53.33 9.202.505.243-1.367 2.755c-5.245-2.603-10.413-2.321-15.696.885l-.48.3-1.658-2.592c6.177-3.951 12.472-4.494 18.697-1.591ZM23.078 24.518l-3.482 9.245.431.343c7.93 6.28 15.966 10.1 24.12 11.486l-21.07-21.074Zm37.206-.509 5.782 2.106-1.579 4.337-5.781-2.106 1.578-4.337ZM58.405 4.068l3.07.172c-.529 9.365-4.703 16.786-12.444 22.142l-.546.37-1.705-2.561C53.89 19.46 57.728 13 58.363 4.694l.042-.626ZM38.092 19.043a1.539 1.539 0 1 1 0 3.078 1.539 1.539 0 0 1 0-3.078ZM29.077.266c4.31 6.943 4.408 14.063.298 21.12l-.292.492-2.628-1.603c3.707-6.073 3.8-11.97.285-17.929l-.278-.458L29.077.266Zm38.692 14.657a1.539 1.539 0 1 1 0 3.078 1.539 1.539 0 0 1 0-3.078ZM23.154 8.77a1.539 1.539 0 1 1-.001 3.078 1.539 1.539 0 0 1 0-3.078Zm18.44-5.303 4.183 1.95-2.602 5.58-4.183-1.951 2.602-5.579Z"
													/>
												</g>
											</svg>
										</div>
										<h3 className="display3">{form.confirmation.title}</h3>
										<p className="mt-30">{form.confirmation.message}</p>
									</>
								) : (
									<>
										<h3 className="display3">{form.title}</h3>
										<p className="mt-30 mb-40">{form.message}</p>
										<ScheduleDemoForm
											cta={form.cta}
											initialValues={{
												campaign: title,
											}}
											onSubmitHandler={() => {
												setIsFormSubmitted(true)
											}}
										/>
									</>
								)}
							</div>
						</aside>
					)}
				</Grid>
			</article>
			<div className="flex flex-row justify-center items-center mt-60 gap-x-40 mb-spacer-xl">
				<SocialShareButtons title={title} url={`${siteUrl}${location.pathname}`} />
			</div>
		</Layout>
	)
}

export default BlogPage

// export async function config() {
// 	return ({ params }) => {
// 		return {
// 			defer: true,
// 		}
// 	}
// }

export const pageQuery = graphql`
	query ($id: String) {
		backgroundImage: file(relativePath: { eq: "gradient.png" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
		}

		mongodbBloghanseaticsoftArticles(id: { eq: $id }) {
			slug
			id
			excerpt
			title
			author {
				firstName
				lastName
				email
				title
			}
			markdown {
				childMdx {
					body
				}
			}
			published_at(formatString: "MMMM DD, YYYY")

			datePublished: published_at
			dateModified: updated_at

			seoImage: teaser_image {
				imgixImage {
					url(imgixParams: { w: 1200, h: 628, fit: "crop", auto: "format,compress" })
				}
			}
			seoImage1x1: teaser_image {
				imgixImage {
					url(imgixParams: { w: 1200, ar: "1x1", fit: "crop", auto: "format,compress" })
				}
			}
			seoImage4x3: teaser_image {
				imgixImage {
					url(imgixParams: { w: 1200, ar: "4x3", fit: "crop", auto: "format,compress" })
				}
			}
			seoImage16x9: teaser_image {
				imgixImage {
					url(imgixParams: { w: 1200, ar: "16:9", fit: "crop", auto: "format,compress" })
				}
			}

			teaser_image {
				imgixImage {
					gatsbyImageData(
						layout: CONSTRAINED
						width: 1340
						height: 550
						placeholder: BLURRED
					)
				}
			}

			form {
				show
				title
				message
				cta
				# tracking {
				# 	salesforce {
				# 		enabled
				# 	}
				# }
				# webhook {
				# 	enabled
				# }
				confirmation {
					title
					message
				}
			}
		}
	}
`
